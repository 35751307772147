<template>
  <b-card>
    <div
      v-if="userDetails"
      class="container user-edit"
    >
      <div class="row form-block">
        <div class="form-group col col-md-12">
          <label>First Name</label>
          <input
            v-model="userDetails.firstName"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('firstName') }"
            class="form-control"
            name="firstName"
            placeholder="First Name"
            size="sm"
          >
          <div class="invalid-feedback">
            {{ errors.first('firstName') }}
          </div>
        </div>
        <div class="form-group col col-md-12">
          <label>Last Name</label>
          <input
            v-model="userDetails.lastName"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('lastName') }"
            class="form-control"
            name="lastName"
            placeholder="Last Name"
            size="sm"
          >
          <div class="invalid-feedback">
            {{ errors.first('lastName') }}
          </div>
        </div>
        <div class="form-group col col-md-12">
          <label>Email</label>
          <input
            v-model="userDetails.email"
            v-validate="'required|email|min:6'"
            :class="{ 'is-invalid': errors.has('email') }"
            class="form-control"
            name="email"
            placeholder="Email"
            size="sm"
          >
          <div class="invalid-feedback">
            {{ errors.first('email') }}
          </div>
        </div>
        <div class="form-group col col-md-12">
          <label>Phone Number</label>
          <vue-tel-input
            v-model="userDetails.phoneNumber"
            v-validate="`isValidPhoneNumber:${isPhoneValid}`"
            :class="{ 'is-invalid': errors.has('phoneNumber') }"
            name="phoneNumber"
            v-bind="phoneProps"
            placeholder="Phone Number"
            size="sm"
            @validate="checkPhoneNumberValidity"
          />
          <div class="invalid-feedback">
            {{ errors.first('phoneNumber') }}
          </div>
        </div>
        <div class="form-group form-check  col-12">
          <label class="form-check-label mr-5">Enabled</label>
          <b-form-checkbox
            v-model="userDetails.enabled"
            class="d-inline"
          />
        </div>
        <div class="form-group col-12 row">
          <label class="form-check-label col-7">Multi-Factor Authentication</label>
          <SwitchField
            class="col-5"
            :value="userDetails.mfaEnabled"
            :disabled="!userDetails.mfaEnabled"
            @input="onMFAInput"
          />
        </div>
        <div class="form-group col col-md-12">
          <label>Roles</label>
          <div class="row mx-auto">
            <div
              v-for="role in roles"
              :key="role.name"
              class="d-flex col-3 p-0"
            >
              <b-form-checkbox
                v-model="userDetails.roles"
                type="checkbox"
                :value="role.name"
              />
              {{ role.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <button
        class="btn btn-primary mr-3"
        @click="saveUser"
      >
        Save
      </button>
      <button
        class="btn plain"
        @click="cancelEdit"
      >
        <em>Cancel</em>
      </button>
    </div>
  </b-card>
</template>

<script>
import { Warning, SwitchField } from '@microbadevs/library'
import { Validator } from 'vee-validate'
import installValidatorRules from '../../Models/validatorRules'
installValidatorRules(Validator)

export default {
  name: 'UserEditUI',
  components: { SwitchField },
  props: ['userInfo', 'roles'],
  data () {
    return {
      userDetails: null,
      isPhoneValid: null,
      phoneProps: {
        defaultCountry: 'AU',
        disabledFetchingCountry: false,
        placeholder: 'Enter phone number',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['AU'],
        onlyCountries: ['AU', 'NZ'],
        ignoredCountries: [],
        autocomplete: 'tel',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: 'form-control',
        inputClasses: 'phoneField'
      }
    }
  },
  mounted () {
    this.hydrateUserDetails()
  },
  methods: {
    hydrateUserDetails () {
      this.userDetails = {
        id: this.userInfo?.id,
        firstName: this.userInfo?.firstName,
        lastName: this.userInfo?.lastName,
        email: this.userInfo?.email,
        enabled: this.userInfo?.enabled,
        mfaEnabled: this.userInfo?.mfaEnabled,
        roles: this.userInfo?.roles,
        phoneNumber: this.userInfo?.phoneNumber
      }
    },
    checkPhoneNumberValidity (payload) {
      this.isPhoneValid = payload?.isValid
      this.userDetails.phoneNumber = this.isPhoneValid
        ? payload?.number.e164
        : payload?.number.input
    },
    cancelEdit () {
      this.$emit('cancelEdit')
    },
    onMFAInput: function (value) {
      let payload = {
        id: this.userDetails.id,
        mfaEnabled: value
      }
      this.$store.dispatch('patchUser', payload)
        .then((response) => {
          this.$emit('fetchUpdatedDetails')
        })
        .catch((error) => {
          Warning({ text: `Failed to update user: ${error}` })
        })
    },
    saveUser () {
      this.$validator.validateAll().then(() => {
        if (this.errors.any()) {
          return
        } else {
          this.$store
            .dispatch('patchUser', this.userDetails)
            .then((response) => {
              this.$emit('fetchUpdatedDetails')
            })
            .catch((error) => {
              Warning({ text: `Failed to update user: ${error}` })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.user-edit {
  .vue-tel-input {
    ul {
      z-index: 2;
    }
  }
}
</style>
