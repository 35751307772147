<template>
  <div class="box box-shadow">
    <h3>
      Shipping Orders
      <span v-if="isRegularDispatch">
        <i>
          <small>(Click to select shipping order for priority dispatch.)</small>
        </i>
      </span>
    </h3>
    <div class="pull-right mb-3">
      <b-form-group label="Filter by product code">
        <b-form-select v-model="productCodeFilter">
          <b-form-select-option :value="null"> No Filter </b-form-select-option>
          <b-form-select-option
            v-for="product in uniqueProducts"
            :key="product.code"
            :value="product.code"
          >
            {{ product.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>
    <b-table
      ref="shippingOrdersTable"
      :fields="fieldsList"
      :items="filteredShippingOrders"
      :select-mode="selectMode"
      :selectable="isSelectable"
      :sort-desc.sync="sortDesc"
      hover
      show-empty
      striped
      @row-selected="selectNextShippingOrder"
    >
      <template v-slot:cell(name)="data">
        {{ data.item.firstName }} {{ data.item.lastName }}
      </template>
      <template v-slot:cell(address)="data">
        {{ data.item.addressLine1 }} {{ data.item.addressLine2 }}
        <br />
        {{ data.item.suburb }}
        - {{ data.item.postcode }}
        <br />
        {{ data.item.state }}
        <br />
        <i
          aria-hidden="true"
          class="fas fa-exclamation-triangle"
          style="color: red"
          v-if="!data.item.gnafId && !data.item.afUuid"
        />
      </template>
      <template v-slot:cell(project)="data">
        <span v-if="!isRegularDispatch">
          {{ data.item.researchProjectName }} ({{
            data.item.researchProjectId
          }})
        </span>
      </template>
      <template v-slot:cell(labelActivationCode)="data">
        <span v-if="usesActivationLabel(data)">
          {{ data.item.kitHashId }}
        </span>
      </template>
      <template v-slot:cell(collectionDevices)="data">
        <div
          v-for="(fulfillment, index) in data.item.fulfillmentCondition
            .fulfillment"
          :key="index"
        >
          <span class="bold"> {{ fulfillment.consumableType }}: </span>
          {{ fulfillment.sampleIdentifier || 'unfulfilled' }}
        </div>
      </template>
      <!-- The actions column -->
      <template v-slot:cell(actions)="row">
        <b-link
          v-if="isDispatched(row) && isOutgoingLabelApplicable(row)"
          v-b-popover.hover="'Print outgoing label'"
          @click="
            printLabels(row.item, row.index, $event.target, labelTypes.OUTGOING)
          "
        >
          <i aria-hidden="true" class="fa fa-arrow-circle-up fa-2x" />
        </b-link>
        <b-link
          v-if="isDispatched(row) && isIncomingLabelApplicable(row)"
          v-b-popover.hover="'Print return label'"
          @click="
            printLabels(row.item, row.index, $event.target, labelTypes.INCOMING)
          "
        >
          <i aria-hidden="true" class="fa fa-arrow-circle-down fa-2x" />
        </b-link>
        <b-link
          v-if="isDispatched(row)"
          v-b-popover.hover="'Print name label'"
          @click="
            printLabels(
              row.item,
              row.index,
              $event.target,
              dispatchType === dispatchTypes.DISTRIBUTOR
                ? labelTypes.ACTIVATION_CODE
                : labelTypes.NAME
            )
          "
        >
          <i aria-hidden="true" class="fa fa-id-card fa-2x" />
        </b-link>
        <b-link
          v-if="isDispatched(row) && usesActivationLabel(row)"
          v-b-popover.hover="'Print activation label'"
          @click="
            printLabels(
              row.item,
              row.index,
              $event.target,
              labelTypes.ACTIVATION_CODE
            )
          "
        >
          <i aria-hidden="true" class="fa fa-bolt fa-2x" />
        </b-link>
        <b-link
          v-if="isDispatched(row)"
          v-b-popover.hover="'Remove all shipments (Dispatched but skip post)'"
          @click="removeAllShipments(row.item, row.index, $event.target)"
        >
          <i aria-hidden="true" class="fa fa-trash fa-2x" />
        </b-link>
        <b-link
          v-if="isDispatched(row)"
          v-b-popover.hover="`Un-dispatch (kitId ${row.item.kitId})`"
          @click="unDispatch(row.item, row.index, $event.target)"
        >
          <i aria-hidden="true" class="fa fa-chain-broken fa-2x" />
        </b-link>
        <b-link
          v-if="isPartiallyDispatched(row)"
          v-b-popover.hover="`Un-dispatch (kitId ${row.item.kitId})`"
          @click="clearFulfillments(row.item, row.index, $event.target)"
        >
          <i aria-hidden="true" class="fa fa-chain-broken fa-2x" />
        </b-link>

        <b-link
          v-if="isDispatched(row) && hasDispatches(row)"
          v-b-popover.hover="'See shipments'"
          @click="row.toggleDetails"
        >
          <i aria-hidden="true" class="fa fa-truck fa-2x" />
        </b-link>
        <b-link
          v-if="!isDispatched(row)"
          v-b-popover.hover="'Edit address'"
          @click="row.toggleDetails"
        >
          <i aria-hidden="true" class="fa fa-edit fa-2x" />
        </b-link>
        <b-link
          v-if="!isDispatched(row) && isRequested(row)"
          v-b-popover.hover="'Delete Shipping Order'"
          @click="deleteShippingOrder(row.item, row.index, $event.target)"
        >
          <i aria-hidden="true" class="fa fa-minus-circle fa-2x" />
        </b-link>
      </template>

      <!-- The details row -->
      <template v-slot:row-details="row">
        <div>
          <b-card v-if="isDispatched(row)">
            <!-- Show the shipment details -->
            <b-row class="mt-0">
              <b-col sm="9">
                <b>Shipments:</b>
              </b-col>
              <b-col sm="3 text-right" @click="row.toggleDetails">
                <i aria-hidden="true" class="fa fa-close" />
              </b-col>
            </b-row>
            <b-row
              v-for="dispatchment in row.item.dispatchments"
              :key="dispatchment.id"
            >
              <b-col sm="1">
                {{ dispatchment.id }}
              </b-col>
              <b-col>{{ dispatchment.dispatchType }}</b-col>
              <b-col>{{ dispatchment.trackingIdentifier }}</b-col>
              <b-col>{{ dispatchment.type }}</b-col>
              <b-col>
                <b-link
                  v-b-popover.hover="'Delete This Shipment'"
                  @click="deleteDispatchment(dispatchment, row, $event.target)"
                >
                  <i aria-hidden="true" class="fa fa-minus-circle fa-2x" />
                </b-link>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-else>
            <!-- Edit the address form -->
            <b-row>
              <b-col>
                <shippingAddress
                  :existing-address="getExistingAddress(row)"
                  @updateAddress="updateExistingAddress($event, row)"
                />
              </b-col>
            </b-row>
          </b-card>
        </div>
      </template>
    </b-table>

    <!-- The legend of icons -->
    <div class="icons-legend">
      <b-link> <i aria-hidden="true" class="fa fa-arrow-circle-up" /> </b-link
      >Print outgoing label
      <b-link> <i aria-hidden="true" class="fa fa-arrow-circle-down" /> </b-link
      >Print incoming label
      <b-link> <i aria-hidden="true" class="fa fa-id-card" /> </b-link>Print
      name label
      <b-link> <i aria-hidden="true" class="fa fa-bolt" /> </b-link>Print
      activation label
      <b-link> <i aria-hidden="true" class="fa fa-edit" /> </b-link>Edit the
      address
    </div>
    <div class="icons-legend">
      <b-link> <i aria-hidden="true" class="fa fa-trash" /> </b-link>Remove all
      shipments (Dispatched but skip post)
      <b-link> <i aria-hidden="true" class="fa fa-minus-circle" /> </b-link
      >Delete Shipment
      <b-link> <i aria-hidden="true" class="fa fa-chain-broken" /> </b-link
      >Un-dispatch
      <b-link> <i aria-hidden="true" class="fa fa-truck" /> </b-link>Shipments
    </div>

    <div class="container">
      <div class="row mt-3">
        <!-- Notify of any errors -->
        <div class="col-sm-9">
          There are {{ countDispatched }} orders with shipments and
          {{ countNotDispatched }} orders with no shipments
          <template v-if="errorsInShipments">
            <div
              v-for="error in errorsInShipments"
              :key="`errorsInShipments-${error}`"
              v-html="`<b>Error:</b> ${error}`"
            />
          </template>
        </div>

        <!-- Place the shipping order -->
        <div v-if="canPlaceOrder" class="form-group col-sm-3">
          <button
            class="btn btn-primary pull-right"
            type="button"
            @click="placeOrder"
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dispatchTypes } from '@/enums/dispatchTypes'
import logisticTypes from '@/enums/logisticTypes'
import labelTypes from '@/enums/labelTypes'
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import shippingAddress from './ShippingAddress'
import activationMethods from '@/enums/activationMethods'
import { kitStatuses } from '@/enums/kitItemStatus'

export default {
  name: 'ShippingOrders',
  components: { shippingAddress },
  props: {
    shippingOrders: Array,
    orderPlaced: Boolean,
    dispatchType: {
      required: true,
      type: String,
      default: dispatchTypes.REGULAR
    }
  },
  data() {
    return {
      labelTypes,
      selectMode: 'single',
      sortDesc: false,
      fieldsBaseList: [
        { key: 'shippingOrderId', label: 'Shipping Order #' },
        { key: 'productName', label: 'Product' },
        { key: 'sampleIdentifier', label: 'Sample ID' },
        { key: 'collectionDevices', label: 'Collection Devices' },
        { key: 'name', label: 'Name' },
        { key: 'address', label: 'Address' },
        { key: 'promotionalCode', label: 'Promotional Code' },
        { key: 'voucherCode', label: 'Voucher Code' },
        { key: 'activationCode', label: 'Activation Code' },
        { key: 'labelActivationCode', label: 'Activation Code' },
        { key: 'project', label: 'Project' },
        { key: 'actions', label: 'Action' }
      ],
      productCodeFilter: null
    }
  },
  computed: {
    dispatchTypes() {
      return dispatchTypes
    },
    regularInsightFields() {
      // All fields except project and activation code
      return this.fieldsBaseList.filter(
        (x) =>
          ['project', 'activationCode', 'sampleIdentifier'].indexOf(x.key) ===
          -1
      )
    },
    distributorFields() {
      // All fields except project, voucher code and promotional code
      return this.fieldsBaseList.filter(
        (x) =>
          [
            'project',
            'voucherCode',
            'promotionalCode',
            'labelActivationCode',
            'collectionDevices'
          ].indexOf(x.key) === -1
      )
    },
    researchFields() {
      return this.fieldsBaseList.filter(
        (x) =>
          ['collectionDevices', 'labelActivationCode'].indexOf(x.key) === -1
      )
    },
    fieldsList() {
      const list =
        this.dispatchType === dispatchTypes.REGULAR
          ? this.regularInsightFields
          : this.dispatchType === dispatchTypes.DISTRIBUTOR
          ? this.distributorFields
          : this.researchFields
      return list
    },
    canPlaceOrder() {
      return (
        this.filteredShippingOrders.filter((order) => order?.dispatched)
          .length > 0
      )
    },
    isRegularDispatch() {
      return this.dispatchType === dispatchTypes.REGULAR
    },
    isDistributorDispatch() {
      return this.dispatchType === dispatchTypes.DISTRIBUTOR
    },
    isSelectable() {
      return (
        this.dispatchType === dispatchTypes.REGULAR ||
        this.dispatchType === dispatchTypes.DISTRIBUTOR ||
        this.dispatchType === dispatchTypes.RESEARCH_MICROBA_MANAGED ||
        this.dispatchType === dispatchTypes.RESEARCH_CLIENT_MANAGED
      )
    },
    countDispatched() {
      return (
        this.filteredShippingOrders.filter(
          (shippingOrder) => shippingOrder?.dispatched
        ).length || 0
      )
    },
    countNotDispatched() {
      return (
        this.filteredShippingOrders.filter(
          (shippingOrder) => !shippingOrder?.dispatched
        ).length || 0
      )
    },
    errorsInShipments() {
      const errorsInShipmentsArray = []
      const ordersWithoutDispatchments = []
      const ordersWithIncorrectDispatchments = []
      const ordersWithNoIncoming = []
      const ordersWithNoOutgoing = []
      this.filteredShippingOrders.forEach((order) => {
        if (!order?.dispatchments || !order.dispatchments.length) {
          ordersWithoutDispatchments.push(order.id)
        } else if (order.dispatchments.length > 2) {
          ordersWithIncorrectDispatchments.push(order.id)
        } else {
          const hasIncoming = !!order.dispatchments.find(
            (o) => o.type === 'INCOMING'
          )
          const hasOutgoing = !!order.dispatchments.find(
            (o) => o.type === 'OUTGOING'
          )
          if (!hasIncoming) {
            ordersWithNoIncoming.push(order.id)
          }
          if (!hasOutgoing) {
            ordersWithNoOutgoing.push(order.id)
          }
        }
      })
      if (ordersWithoutDispatchments.length) {
        errorsInShipmentsArray.push(
          `These orders don't have <b>any</b> shipments: <b>${ordersWithoutDispatchments.join(
            ', '
          )}</b>`
        )
      }
      if (ordersWithIncorrectDispatchments.length) {
        errorsInShipmentsArray.push(
          `These orders should have <b>only 2</b> shipments: <b>${ordersWithIncorrectDispatchments.join(
            ', '
          )}</b>`
        )
      }
      if (ordersWithNoIncoming.length) {
        errorsInShipmentsArray.push(
          `These orders should have an <b>incoming</b> shipment: <b>${ordersWithNoIncoming.join(
            ', '
          )}</b>`
        )
      }
      if (ordersWithNoOutgoing.length) {
        errorsInShipmentsArray.push(
          `These orders should have an <b>outgoing</b> shipment: <b>${ordersWithNoOutgoing.join(
            ', '
          )}</b>`
        )
      }
      return errorsInShipmentsArray.length ? errorsInShipmentsArray : null
    },
    uniqueProducts() {
      const products = this.shippingOrders.map((item) => ({
        name: item.productName,
        code: item.productCode
      }))
      return Array.from(new Set(products.map((product) => product.code))).map(
        (code) => {
          const product = products.find((product) => product.code === code)
          return {
            name: product.name,
            code: product.code
          }
        }
      )
    },
    filteredShippingOrders() {
      if (this.productCodeFilter) {
        return this.shippingOrders.filter(
          (order) => order.productCode === this.productCodeFilter
        )
      } else {
        return this.shippingOrders
      }
    }
  },
  watch: {
    dispatchType: function () {
      const [nextShippingOrder] = this.filteredShippingOrders
      this.selectNextShippingOrder([nextShippingOrder])
    },
    productCodeFilter: function () {
      const [nextShippingOrder] = this.filteredShippingOrders
      this.selectNextShippingOrder([nextShippingOrder])
    },
    filteredShippingOrders: function () {
      const [nextShippingOrder] = this.filteredShippingOrders
      this.selectNextShippingOrder([nextShippingOrder])
    }
  },
  methods: {
    printLabels(item, index, target, labelType) {
      this.$emit('manually-print-labels', {
        item: item,
        labelType: labelType
      })
    },
    removeAllShipments(item, index, target) {
      this.$emit('remove-all-shipments', { item: item })
    },
    unDispatch(item, index, target) {
      this.$emit('undispatch-sample', { item: item })
    },
    clearFulfillments(item, index, target) {
      this.$emit('clear-fulfillments', { item: item })
    },
    selectNextShippingOrder(payload) {
      const [selectedOrder] = payload
      this.$emit(
        'next-shipping-order',
        selectedOrder && !selectedOrder.dispatched ? selectedOrder : null
      )
    },
    isPartiallyDispatched(row) {
      const fulfillmentCondition = row.item.fulfillmentCondition
      const fulfillmentIds =
        fulfillmentCondition?.fulfillment?.map(
          (sample) => sample.sampleIdentifier
        ) || []
      return !this.isDispatched(row) && fulfillmentIds.some((id) => Boolean(id))
    },
    isDispatched(row) {
      return row.item.dispatched
    },
    async isRequested(row) {
      const res = await this.$store.dispatch(
        'getKitInfoByKitId',
        row.item.kitId
      )
      return res.data.kitStatus === kitStatuses.REQUESTED
    },
    hasDispatches(row) {
      return Boolean(
        row.item.dispatchments && row.item.dispatchments.length > 0
      )
    },
    usesActivationLabel(row) {
      return row.item.kitSpec?.activationMethod === activationMethods.LABEL
    },
    isOutgoingLabelApplicable(row) {
      return this.isRegularDispatch || this.isDistributorDispatch
        ? true
        : row.item.logisticType === logisticTypes.MICROBA_MANAGED
    },
    isIncomingLabelApplicable(row) {
      return this.isRegularDispatch || this.isDistributorDispatch
    },
    deleteShippingOrder(item, index, target) {
      this.$emit('delete-shipping-order', { item: item })
    },
    deleteDispatchment(item, row) {
      this.$emit('delete-dispatchment', { item: item })
    },
    placeOrder() {
      if (!this.errorsInShipments) {
        this.$emit('place-order', this.dispatchType)
        return
      }

      Notification({
        title: 'Place the shipping orders',
        text: `There are some errors. Do you want to place the order anyway?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.$emit('place-order', this.dispatchType)
        }
      })
    },
    getExistingAddress(row) {
      const addressLine1 = row.item.addressLine1
      const addressLine2 =
        row.item.addressLine2 && row.item.addressLine2.length
          ? `, ${row.item.addressLine2}`
          : ''
      const addressLine = `${addressLine1} ${addressLine2}`
      return {
        addressLine1: addressLine || null,
        suburb: row.item.suburb || null,
        state: row.item.state || null,
        postcode: row.item.postcode || null,
        country: row.item.country || null,
        externalIdentifier: row.item.externalIdentifier || null,
        provider: row.item.provider || null
      }
    },
    updateExistingAddress(payload, row) {
      row.toggleDetails()
      this.$emit('save-shipping-order', {
        id: row.item.id,
        ...payload
      })
    }
  }
}
</script>

<style lang="scss">
.icons-legend {
  text-align: right;

  i {
    padding-left: 20px;
  }
}
</style>
