import store from '@/stores'
import productTypes from '@/enums/productTypes'
import SamplesGroup from '@/Models/samplesGroup'
import resultDeliveryTypes from '@/enums/resultDeliveryTypes'
export default class SampleService {
  static async getSamplesByStatus (status) {
    if (!status) throw new Error('Missing status')

    return store.dispatch('getSamples', { status })
  }

  static async getPlatePreparationCandidates () {
    const { data: samples } = await store.dispatch(
      'getPlatePreparationCandidates'
    )
    return samples || []
  }

  static getInsightSamples (allSamples = []) {
    return allSamples.filter((sample) => {
      return sample.productType === productTypes.CONSUMER
    })
  }

  static getWhiteLabelSamples (allSamples = []) {
    return allSamples.filter(
      (sample) => sample.productType === productTypes.WHITE_LABEL
    )
  }

  static getWhiteLabelSamplesGroupByDistributor (whiteLabelSamples = []) {
    const distrinctDistributorIds = [
      ...new Set(whiteLabelSamples.map((sample) => sample.distributorId))
    ]
    const groupedSamples = groupSamples(
      whiteLabelSamples,
      distrinctDistributorIds,
      'distributorId'
    )
    return groupedSamples
  }

  static getResearchSamples (allSamples = []) {
    return allSamples.filter(
      (sample) => sample.productType === productTypes.RESEARCH
    )
  }

  static getResearchSamplesGroupByProject (researchSamples = []) {
    const distinctProjectIds = [
      ...new Set(researchSamples.map((sample) => sample.researchProjectId))
    ]
    const groupedSamples = groupSamples(
      researchSamples,
      distinctProjectIds,
      'researchProjectId'
    )
    return groupedSamples
  }

  static getResearchSamplesDeliverWhenReady (allSamples = []) {
    return SampleService.getResearchSamples(allSamples)?.filter(
      (sample) =>
        sample.researchProjectResultDeliveryType ===
        resultDeliveryTypes.DELIVERY_WHEN_READY
    )
  }

  static getControlSamples (allSamples = []) {
    return allSamples.filter(
      (sample) =>
        sample?.productType === productTypes.LABORATORY_PROCESSING_CONTROL
    )
  }

  static getLbraasSamples (allSamples = []) {
    return allSamples.filter(
      (sample) => sample?.productType === productTypes.LBRAAS
    )
  }
  static getLbaasSamples (allSamples = []) {
    return allSamples.filter(
      (sample) => sample?.productType === productTypes.LBAAS
    )
  }
  static getBaasSamples (allSamples = []) {
    return allSamples.filter(
      (sample) => sample?.productType === productTypes.BAAS
    )
  }
  static async getSampleByIdentifier (sampleIdentifier) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    const payload = {sampleIdentifier}
    return store.dispatch('getSampleByIdentifier', payload)
  }

  static async getSampleFile (sampleIdentifier, fileId) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    if (!fileId) throw new Error('Missing file ID')
    return store.dispatch('getSampleFile', { sampleIdentifier, fileId })
  }

  static async getSampleFileList (sampleIdentifier) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    return store.dispatch('getSampleFiles', sampleIdentifier)
  }

  static async postSampleFile (sampleIdentifier, formData) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    if (!formData) throw new Error('Missing form data')
    return store.dispatch('postSampleFile', { sampleIdentifier, formData })
  }

  static async deleteSampleFile (sampleIdentifier, fileId) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    if (!fileId) throw new Error('Missing file ID')
    return store.dispatch('deleteSampleFile', { sampleIdentifier, fileId })
  }

  static async getSampleAliquots (sampleIdentifier) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    return store.dispatch('getSampleAliquots', sampleIdentifier)
  }

  static async postGenerateSampleAliquots (sampleIdentifier, params) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    return store.dispatch('postGenerateSampleAliquots', { sampleIdentifier, params })
  }
}

function groupSamples (samples, distinctGroups, groupingKey) {
  if (!groupingKey) return samples

  const groupedSamples = []
  for (const groupId of distinctGroups) {
    const sampleGroup = new SamplesGroup({
      groupId,
      samples: samples?.filter((sample) => sample[groupingKey] === groupId)
    })
    groupedSamples.push(sampleGroup)
  }
  return groupedSamples
}
